import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import Header from '../../components/Header/Header';
import './ContactPage.css'; // Make sure to import your CSS file

const ContactPage = () => {
  return (
    <div className="contact-container">
        
        <Navigation />
        <Header />
        
        <br></br>

        <div className="top-contact-container">
            <h1>Contact Page</h1>
        </div>
        
        <div className='main-content'>
          <section>
            <h2>President/Chief Executive Officer:</h2>
            <span>The CEO oversees the execution of the company's strategy and ensures alignment with its mission. Often serves as the primary liaison between the board of directors and the company's management team. Represents the company to external stakeholders, such as investors, partners, customers, and the public. Ultimately accountable for the company's performance and results.
</span>
            <br></br>
            <br></br>
            <label>Full Name: </label>
            <br></br>
            <br></br>
            <span className='name-span'>Bryce Berwald</span>
            <br></br><br></br>

            <label>Company Email: </label>
            <br></br>
            <br></br>
            <span className='name-span'>developer@berz.io</span><br></br>
            <br></br>

            <label>Company Phone #: </label>
            <br></br>
            <br></br>
            <span className='name-span'>+1 (949) 732-1311</span><br></br>

            {/* Add your contact form or details here */}
          </section>

          <section>
            <h2>Vice President/Chief Executive Officer:</h2>
            <span>Vice President-(VP) helps oversee the day-to-day operations of the company, ensuring that processes run smoothly and efficiently. Responsibilities may include supply chain management, production, quality control, and logistics. This VP is responsible for leading the company's sales efforts. They develop sales strategies, set targets, manage sales teams, and foster relationships with clients and customers.</span>
            <br></br>
            <br></br>
            <label>Full Name: </label>
            <br></br>
            <br></br>
            <span className='name-span'>Steve W</span>
            <br></br><br></br>

            <label>Company Email: </label>
            <br></br>
            <br></br>
            <span className='name-span'>management@berz.io</span><br></br>
            <br></br>

            <label>Company Phone #: </label>
            <br></br>
            <br></br>
            <span className='name-span'>+1 (xxx) xxx-xxxx</span><br></br>

            {/* Add your contact form or details here */}
          </section>
        </div>

    </div>
  );
};

export default ContactPage;
