import * as React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import Header from '../../components/Header/Header';
import './MissionPage.css';

export default function MissionPage() {

  return (
    <div className='mission-container'>
      <Navigation />
      <Header />
      <br></br>

      <div className="top-mission-container">
        <h1>Mission Statement Page</h1>
      </div>

      <br></br>
      <div className="main-content">
        <section>
          <h2>Our Company Overview </h2>
          <p>
            Welcome to our Freelancing Tech Startup, dedicated to empowering small businesses in our community with the transformative capabilities of modern technology. We specialize in delivering tailored solutions that not only fit within your financial parameters but also unlock opportunities you may have never imagined.
            At our core, we believe in leveraging technology to propel businesses forward. Whether you're in need of a simple yet elegant website or a sophisticated digital platform designed to maximize your business outcomes, we've got you covered.
            Our team combines expertise with innovation to craft solutions that align perfectly with your unique goals. Through our blend of creativity and technical prowess, we're committed to delivering results that exceed your expectations.
            Let's embark on this journey together, harnessing the mathematical magic of technology to drive your business towards success.
          </p>
        </section>
      </div>

    </div>
  );
};