import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import Header from '../../components/Header/Header';
import './ServicesPage.css';

const ServicesPage = () => {
  return (
    <div className="services-container">
        
        <Navigation />
        <Header />
        <br></br>

        <div className='top-services-container'>
            <h1>Our Service's Page</h1>
        </div>
    </div>
  );
};

export default ServicesPage;