// NavigationData.js
export const NavigationData = [
  {
    title: "Home",
    path: "/",
    cName: "navigation-text",
  },
  {
    title: "Our Services",
    path: "/services",
    cName: "navigation-text",
  },
  {
    title: "Contact Us",
    path: "/contact",
    cName: "navigation-text",
  },
  {
    title: "Mission Statement",
    path: "/mission",
    cName: "navigation-text",
  },  
  {
    title: "Marketplace Store",
    path: "/store",
    cName: "navigation-text",
  },
  {
    title: "Book Appointment",
    path: "/appointment",
    cName: "navigation-text",
  },
];